// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cubing-beginner-jsx": () => import("/opt/build/repo/src/pages/cubing/beginner.jsx" /* webpackChunkName: "component---src-pages-cubing-beginner-jsx" */),
  "component---src-pages-cubing-bld-jsx": () => import("/opt/build/repo/src/pages/cubing/bld.jsx" /* webpackChunkName: "component---src-pages-cubing-bld-jsx" */),
  "component---src-pages-cubing-coll-jsx": () => import("/opt/build/repo/src/pages/cubing/coll.jsx" /* webpackChunkName: "component---src-pages-cubing-coll-jsx" */),
  "component---src-pages-cubing-f-2-l-jsx": () => import("/opt/build/repo/src/pages/cubing/f2l.jsx" /* webpackChunkName: "component---src-pages-cubing-f-2-l-jsx" */),
  "component---src-pages-cubing-index-jsx": () => import("/opt/build/repo/src/pages/cubing/index.jsx" /* webpackChunkName: "component---src-pages-cubing-index-jsx" */),
  "component---src-pages-cubing-intermediate-jsx": () => import("/opt/build/repo/src/pages/cubing/intermediate.jsx" /* webpackChunkName: "component---src-pages-cubing-intermediate-jsx" */),
  "component---src-pages-cubing-oll-jsx": () => import("/opt/build/repo/src/pages/cubing/oll.jsx" /* webpackChunkName: "component---src-pages-cubing-oll-jsx" */),
  "component---src-pages-cubing-pll-jsx": () => import("/opt/build/repo/src/pages/cubing/pll.jsx" /* webpackChunkName: "component---src-pages-cubing-pll-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-software-jsx": () => import("/opt/build/repo/src/pages/software.jsx" /* webpackChunkName: "component---src-pages-software-jsx" */),
  "component---src-pages-violin-jsx": () => import("/opt/build/repo/src/pages/violin.jsx" /* webpackChunkName: "component---src-pages-violin-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

